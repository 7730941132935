<template>
    <main>
        <header
            class="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
            <div class="container-fluid">
                <div class="page-header-content">
                    <div class="row align-items-center justify-content-between pt-3">
                        <div class="col-auto mb-3">
                            <h1 class="page-header-title">
                                <div class="page-header-icon"></div>
                                Relacion de Facturas Eventos
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!-- Main page content-->
        <div class="container-fluid mt-4">
            <div class="card">
                <div class="card-body">
                    <data-grid :data="pagination"
                               @changePage="changePage($event)"
                               @perPageChange="perPageChange($event)"
                               @search="search($event)"
                    >
                        <template #grid>
                            <grid-table>
                                <template #head>
                                    <grid-cell-header v-for="(h,i) in headers" :key="`h_${i}`">
                                        {{ h }}
                                    </grid-cell-header>
                                </template>
                                <template #body>
                                    <grid-row v-for="(invoice, i) in pagination.data" :key="`d_${i}`">
                                        <grid-cell>
                                            <div>
                                                <p class="text-uppercase mt-0 mb-1 font-weight-bold">
                                                    {{ invoice.numero }} - {{ invoice.fecha | formatDate }}
                                                </p>
                                                <p class="mt-0 mb-1 text-xs">
                                                    {{ invoice.usuario }}
                                                </p>
                                                <p class="mt-0 mb-1 text-xs">
                                                    {{ invoice.eps }} - {{ invoice.contrato }}
                                                </p>
                                            </div>
                                        </grid-cell>
                                        <grid-cell class="text-center">
                                            <span class="badge badge-success" v-show="invoice.is_send">
                                                <i class="fas fa-check"></i>
                                                Enviada
                                            </span>
                                            <span class="badge badge-warning" v-show="!invoice.is_send">
                                                <i class="fas fa-times"></i>
                                                No Enviada
                                            </span>
                                        </grid-cell>
                                        <grid-cell>{{ invoice.vlr_total | cop_currency }}</grid-cell>
                                        <grid-cell>{{ invoice.vlr_usuario | cop_currency }}</grid-cell>
                                        <grid-cell>{{ invoice.vlt_neto | cop_currency }}</grid-cell>
                                        <grid-cell class="text-center">
                                            <router-link
                                                :to="{
                                                params : {id:invoice.id},
                                                name: 'facturacion.eventos.edit'
                                            }"
                                                class="btn btn-transparent-dark btn-icon btn-sm"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title=""
                                                data-original-title="Editar">
                                                <i class="fas fa-edit"></i>
                                            </router-link>
                                        </grid-cell>
                                        <grid-cell class="text-center">
                                            <button v-show="!invoice.is_send"
                                                    class="btn btn-transparent-dark btn-icon btn-sm"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Enviar Factura Electronica"
                                                    data-original-title="Enviar Factura Electronica"
                                                    @click="sendElectronicInvoice(invoice.id)">
                                                <i class="fas fa-paper-plane"></i>
                                            </button>
                                            <button v-show="invoice.is_send"
                                                    class="btn btn-transparent-dark btn-icon btn-sm"
                                                    title="Ver Documento Electronico"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    data-original-title="Ver Documento Electronico"
                                                    @click="showElectronicInvoice(invoice.id)">
                                                <i class="far fa-file-pdf"></i>
                                            </button>
                                        </grid-cell>
                                    </grid-row>
                                </template>
                            </grid-table>
                        </template>
                    </data-grid>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import DataGrid from "../../../components/common/utilities/DataGrid/DataGrid";
import facturaService from "../../../services/facturaService";
import GridCellHeader from "../../../components/common/utilities/DataGrid/GridCellHeader";
import GridCell from "../../../components/common/utilities/DataGrid/GridCell";
import GridRow from "../../../components/common/utilities/DataGrid/GridRow";
import GridTable from "../../../components/common/utilities/DataGrid/GridTable";
import Swal from "sweetalert2/src/sweetalert2";
import $ from 'jquery';

export default {
    components: {GridTable, GridRow, GridCell, GridCellHeader, DataGrid},
    data() {
        return {
            headers: [
                'Factura',
                'F. Electronica',
                'Vlr Total',
                'Vlr Usr',
                'Vlr. Neto',
                '',
                ''
            ],
            pagination: {
                data: [],
                per_page: 5,
                search: '',
                total: '',
                current_page: 1,
                page: 1,
            }
        }
    },
    created() {
        this.get();
    },
    mounted() {
        this.$nextTick(function () {
            // Enable Bootstrap tooltips via data-attributes globally
            $('[data-toggle="tooltip"]').tooltip();
            // Enable Bootstrap popovers via data-attributes globally
            $('[data-toggle="popover"]').popover();

            $(".popover-dismiss").popover({
                trigger: "focus",
            });
        });
    },
    methods: {
        async get() {
            try {
                this.LoaderSpinnerShow();

                let response = await facturaService.eventInvoiceList(this.pagination.search, this.pagination);

                this.pagination.data = response.data.data.map(invoice => {
                    return {
                        id: invoice.id,
                        fecha: invoice?.created_at,
                        numero: `${invoice?.prefijo}${invoice?.numero}`,
                        usuario: `${invoice?.usuario?.documento} - ${invoice?.usuario?.nombre_1} ${invoice?.usuario?.nombre_2} ${invoice?.usuario?.apellido_1} ${invoice?.usuario?.apellido_2}`,
                        vlr_total: invoice?.valor_total,
                        vlr_usuario: invoice?.valor_usuario,
                        vlt_neto: invoice?.valor_neto,
                        is_send: (invoice?.id_service !== null && invoice?.id_service !== undefined && invoice?.id_service !== 0),
                        id_service: invoice?.id_service,
                        eps: `${invoice?.eps?.IDEPS} ${invoice?.eps?.DESEPS}`,
                        contrato: `${invoice?.contrato?.codigo} ${invoice?.contrato?.descripcion}`
                    }
                });

                this.pagination.total = response.data.total;
                this.pagination.current_page = response.data.current_page;

                this.LoaderSpinnerHide();

                this.$nextTick(() => $('[data-toggle="tooltip"]').tooltip());

            } catch (e) {
                this.LoaderSpinnerHide();
                console.error(e);
            }

        },
        changePage(page) {
            this.pagination.page = page;
            this.get();
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.get();
        },
        search(query){
            this.pagination.search = query;
            this.get();
        },
        async sendElectronicInvoice(id) {
            let id_factura = id;
            try {
                Swal.fire({
                    title: "Esta seguro de enviar está factura electronicamente ?",
                    showCancelButton: true,
                    icon: "question",
                    confirmButtonText: "Sí, estoy seguro",
                    cancelButtonText: "Cancelar",
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        return facturaService
                            .sendInvoice(id_factura)
                            .then((response) => {
                                return response.data;
                            })
                            .catch((error) => {
                                Swal.showValidationMessage(`Error: ${error.statusText}`);
                            });
                    },
                    allowOutsideClick: () => !Swal.isLoading(),
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (result.value.State === "Exitosa") {

                            Swal.fire({
                                text: `Factura electronica enviada con exito`,
                                icon: "success",
                            });

                            const index = this.pagination.data.findIndex(x => x.id === id_factura);
                            this.pagination.data[index].is_send = true;
                            this.pagination.data[index].id_service = result.value.Id;

                        } else {
                            let message = "";
                            result.value.ErrorList.forEach((element) => {
                                message += `\n ${element}`;
                            });
                            Swal.showValidationMessage(`Error: ${message}`);
                        }
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        showElectronicInvoice(id_factura) {
            window.open(
                `/api/facturacion-electronica/get-event-invoice-document/${id_factura}`,
                "_blank"
            );
        },

    }
}
</script>

<style scoped>

</style>
